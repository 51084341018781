import React from 'react'
import PropTypes from 'prop-types'

import { Header, LogoutHeader } from 'components'
import './BaseLayout.module.scss'

const BaseLayout = ({ children, backRoute, showLogout, handleLogout }) => {
  return (
    <div>
      {!showLogout ? (
        <Header backRoute={backRoute} />
      ) : (
        <LogoutHeader handleClick={handleLogout} />
      )}
      {children}
    </div>
  )
}

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
  backRoute: PropTypes.string,
  showLogout: PropTypes.bool,
  handleLogout: PropTypes.func,
}

BaseLayout.defaultProps = {
  backRoute: '/',
  showLogout: false,
  handleLogout: () => {},
}

export default BaseLayout
