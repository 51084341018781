import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from 'components'
import styles from './LogoutHeader.module.scss'

// TODO: implement  the logo icon  (logout)
const Header = ({ handleClick }) => {
  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <button type="button" className={styles.label} onClick={handleClick}>
          Logout
        </button>
      </div>
      <Icon type="ellipse" />
    </header>
  )
}

Header.propTypes = {
  handleClick: PropTypes.func.isRequired,
}

export default React.memo(Header)
