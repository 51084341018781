import { navigate } from '@reach/router'
import * as providers from './provider'
import * as defaultValues from '../../templates/defaultValues'

export default (setWorkspaces, $workspaces) => {
  const fetchWorkspaces = async () => {
    const [err, res] = await providers.getWorkspacesData()

    if (err) {
      return
    }

    const workspaces = res.data
    setWorkspaces(prev => ({ ...prev, workspaces, loadingHome: false }))
  }

  const closeModal = () => {
    const { excludeConfirmation } = $workspaces
    excludeConfirmation.show = false

    setWorkspaces(prev => ({
      ...prev,
      excludeConfirmation,
    }))
  }

  const deleteWorkspace = async () => {
    const { id } = $workspaces.excludeConfirmation

    setWorkspaces(prev => ({ ...prev, loadingHome: true }))
    const newState = [...$workspaces.workspaces].filter(e => e.id !== id)
    setWorkspaces(prev => ({
      ...prev,
      workspaces: newState,
      loadingHome: false,
    }))
    closeModal()
    await providers.deleteWorkspace(id)
  }

  const changeValue = async (key, value) => {
    const { id } = $workspaces.currentWorkspace
    setWorkspaces(prev => ({
      ...prev,
      currentWorkspace: {
        ...$workspaces.currentWorkspace,
        [key]: value,
      },
    }))

    const payload = {
      [key]: value,
    }

    // TODO: patch workspace value
    await providers.changeRule(payload, id)
  }

  const editWorkspace = id => {
    navigate(`/workspace/id=${id}`)
  }

  const newWorkspace = async () => {
    const payload = {
      name: 'Nova area de trabalho',
      description: '',
      attributes: '',
    }

    const [err, res] = await providers.postWorkspace(payload)
    if (err) {
      return
    }
    const { id } = res.data
    navigate(`/workspace/id=${id}`)
  }

  const fetchRules = async id => {
    const [err, res] = await providers.getRules(id)
    if (err) {
      return
    }

    const rules = res.data
    setWorkspaces(prev => ({
      ...prev,
      loadingWorkspace: false,
      workspaceRules: rules,
    }))
  }

  const fetchWorks = async id => {
    const [err, res] = await providers.getWorkspace(id)
    if (err) {
      navigate('/')
    }
    const { attributes, description, name } = res.data
    setWorkspaces(prev => ({
      ...prev,
      currentWorkspace: {
        ...$workspaces.currentWorkspace,
        id,
        attributes,
        description,
        name,
      },
    }))
  }

  const openModalRule = id => {
    const excludeConfirmation = {
      show: true,
      title: 'Excluir Regra',
      text: 'Você tem certeza que deseja excluir esta regra?',
      id,
    }

    setWorkspaces(prev => ({
      ...prev,
      excludeConfirmation,
    }))
  }

  const openModalWorkspace = id => {
    const excludeConfirmation = {
      show: true,
      title: 'Excluir Workspace',
      text: 'Você tem certeza que deseja excluir este workspace?',
      id,
    }

    setWorkspaces(prev => ({
      ...prev,
      excludeConfirmation,
    }))
  }

  const makeCopy = async index => {
    setWorkspaces(prev => ({ ...prev, loadingWorkspace: true }))
    const { id } = $workspaces.currentWorkspace
    const copy = $workspaces.workspaceRules[index]
    const { name, description, attributes, parameters } = copy

    const payload = {
      name: `${name} copia`,
      description,
      attributes,
      parameters,
    }
    const [err] = await providers.postCopy(id, payload)
    if (err) {
      setWorkspaces(prev => ({ ...prev, loadingWorkspace: false }))
      return
    }
    fetchRules(id)
  }

  const deleteRule = async () => {
    setWorkspaces(prev => ({ ...prev, loadingWorkspace: true }))
    const { id } = $workspaces.currentWorkspace
    const { id: ruleId } = $workspaces.excludeConfirmation

    closeModal()
    const [err] = await providers.deleteRule(id, ruleId)
    if (err) {
      setWorkspaces(prev => ({ ...prev, loadingWorkspace: false }))
      return
    }
    fetchRules(id)
  }

  const editRule = async ruleId => {
    const { id: workspaceId } = $workspaces.currentWorkspace
    navigate(`/rule/workspace=${workspaceId}/id=${ruleId}`)
  }

  const createRule = async () => {
    const { id: workspaceId } = $workspaces.currentWorkspace
    const payload = {
      name: 'Nova regra',
      description: '',
      attributes: '',
      parameters: defaultValues.initialParameters,
    }
    const [err, res] = await providers.postRule(payload, workspaceId)
    if (err) {
      return
    }
    const { id } = res.data
    fetchRules(workspaceId)
    navigate(`/rule/workspace=${workspaceId}/id=${id}`)
  }

  return {
    fetchWorkspaces,
    deleteWorkspace,
    changeValue,
    editWorkspace,
    newWorkspace,
    fetchWorks,
    fetchRules,
    makeCopy,
    editRule,
    deleteRule,
    createRule,
    openModalRule,
    openModalWorkspace,
    closeModal,
  }
}
