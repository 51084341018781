import { useState, useEffect } from 'react'
import useCreateStore from '../../lib/useCreateStore'
import trainingPeriodActions from './actions'
import trainingPeriodSelectors from './selectors'

const initialState = {
  trainingPeriod: [],
}

const TrainingPeriodStore = useCreateStore(() => {
  const [$trainingPeriod, setTrainingPeriod] = useState(initialState)
  const actions = trainingPeriodActions(setTrainingPeriod)
  const selectors = trainingPeriodSelectors($trainingPeriod)

  useEffect(() => {
    const fetch = async () => {
      await actions.fetchTrainingPeriod()
    }
    fetch()
  }, []) // eslint-disable-line

  return { $trainingPeriod, ...actions, ...selectors }
})

export const useTrainingPeriod = () => TrainingPeriodStore()
export const TrainingPeriodContext = TrainingPeriodStore.Context
export const TrainingPeriodProvider = TrainingPeriodStore.Provider
