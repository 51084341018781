/* global window */
import { useState, useEffect } from 'react'
import split from 'lodash/split'
import { createHistory } from '@reach/router'

import useCreateStore from '../../lib/useCreateStore'
import ruleActions from './actions'
import ruleSelectors from './selectors'
import * as defaultValues from '../../templates/defaultValues'

const initialState = {
  loading: true,
  workspaceId: null,
  id: null,
  name: '',
  description: '',
  attributes: null,
  parameters: defaultValues.initialParameters,
  selectedLeafId: null,
  selectedLeafName: 'Leaf Teste',
  errorMessage: '',
  canSimulate: true,
  results: null,
}

const RuleStore = useCreateStore(() => {
  const [$rule, setRule] = useState(initialState)
  const actions = ruleActions(setRule, $rule)
  const selectors = ruleSelectors($rule)

  const history = createHistory(window)

  useEffect(() => {
    const pathIds = split(history.location.pathname, '=', 3)

    const fetch = async () => {
      if (pathIds.length === 3) {
        const workspaceId = pathIds[1].replace('/id', '')
        const ruleId = pathIds[2]

        await actions.fetchRule(workspaceId, ruleId)
      }
    }

    fetch()
  }, []) // eslint-disable-line

  return { $rule, ...actions, ...selectors }
})

export const useRule = () => RuleStore()
export const RuleContext = RuleStore.Context
export const RuleProvider = RuleStore.Provider
