/* eslint-disable react/jsx-no-duplicate-props, react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'
import { Label } from 'semantic-ui-react'

import { Icon } from 'components'
import styles from './Dropdown.module.scss'

const Dropdown = ({
  values,
  search,
  setSearch,
  hasIcon,
  addItem,
  placeholder,
  after3letters,
  hasClear,
  allowEmpty,
}) => {
  const [listVisible, setListVisible] = React.useState(false)
  const [label, setLabel] = React.useState(null)

  const filterValues = val => {
    if (after3letters && search.length < 3) {
      return val
    }
    return val.filter(({ value }) =>
      value
        .toString()
        .toLocaleLowerCase()
        .includes(search.toString().toLocaleLowerCase())
    )
  }

  const handleChange = value => {
    setSearch(value)
    let message

    if (!allowEmpty && (!value || value === '')) {
      message = `Valor obrigatório`
    } else {
      setLabel(null)
      return
    }

    setLabel(
      <Label basic color="red" pointing="left" size="mini">
        {message}
      </Label>
    )
  }

  return (
    <div className={styles.div} onMouseLeave={() => setListVisible(false)}>
      <div className={styles.search}>
        {hasIcon && <Icon type="magnifyingglass" />}
        <input
          type="text"
          placeholder={placeholder}
          value={search}
          onChange={e => handleChange(e.target.value)}
          className={styles.input}
          onMouseEnter={() => setListVisible(true)}
        />
        {hasClear && (
          <button
            className={styles.delete}
            type="button"
            onClick={() => handleChange('')}
          >
            <Icon type="delete" />
          </button>
        )}
        <div className={styles.label}>{label}</div>
      </div>
      {listVisible && (
        <ul className={styles.list}>
          {filterValues(values).map(({ name, value }, i) => (
            <button
              key={name}
              type="button"
              id={name}
              key={i}
              className={styles.item}
              onKeyPress={() => {}}
              onClick={() => [
                handleChange(value),
                setListVisible(false),
                addItem(value),
              ]}
            >
              {value}
            </button>
          ))}
        </ul>
      )}
    </div>
  )
}

Dropdown.propTypes = {
  values: PropTypes.arrayOf(PropTypes.object),
  search: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSearch: PropTypes.func.isRequired,
  hasIcon: PropTypes.bool,
  addItem: PropTypes.func,
  placeholder: PropTypes.string,
  after3letters: PropTypes.bool,
  hasClear: PropTypes.bool,
  allowEmpty: PropTypes.bool,
}

Dropdown.defaultProps = {
  values: [],
  search: '',
  hasIcon: false,
  addItem: () => {},
  placeholder: 'Buscar..',
  after3letters: false,
  hasClear: false,
  allowEmpty: false,
}

export default Dropdown
