/* eslint-disable max-params */
import * as d3 from 'd3'
import dagreD3 from 'dagre-d3'
import { D3_GRAPH_NODE_SEP, D3_GRAPH_RANK_SEP } from 'utils/constants'
import { toMoney } from 'lib/utils'

const LEAVES_CONTAINER = 'leaves'

export const createGraph = (compound = true) => {
  const graph = new dagreD3.graphlib.Graph({ compound })
    .setGraph({})
    .setDefaultEdgeLabel(() => {})

  graph.graph().rankDir = 'LR'
  graph.graph().nodeSep = D3_GRAPH_NODE_SEP
  graph.graph().rankSep = D3_GRAPH_RANK_SEP
  graph.setNode(LEAVES_CONTAINER, {
    style: 'fill: transparent',
  })

  return graph
}

export const addNode = (graph, node) => {
  const { id, label, isLeaf } = node

  graph.setNode(id, { label })

  if (isLeaf) {
    graph.setParent(id, LEAVES_CONTAINER)
  }
}

export const addEdge = (graph, origin, destination, props = {}) => {
  graph.setEdge(origin, destination, props)
}

export const renderCluster = (graph, data) => {
  const renderer = new dagreD3.render() // eslint-disable-line

  const svg = d3.select('#decisionTree')
  const inner = svg.append('g')

  renderer(inner, graph)

  const nodes = inner.selectAll('g.node')

  nodes.attr('data-tip', id => {
    const element = data.find(x => x.id.toString() === id)
    return `Casos: ${element.n_samples}, Valor: ${toMoney(element.value)}`
  })

  svg.attr('width', graph.graph().width)
  svg.attr('height', graph.graph().height)
}
