/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Icon } from 'components'
import PropTypes from 'prop-types'

import styles from './DeleteItens.module.scss'

const DeleteItens = ({ deletedList, setDeletedList }) => {
  return (
    <div>
      <div className={styles.deletedItens}>
        {deletedList.length > 0 &&
          deletedList.map((item, i) => (
            <div className={styles.item} key={i}>
              <p>{item}</p>
              <button type="button" onClick={() => setDeletedList(i)}>
                <Icon type="delete" />
              </button>
            </div>
          ))}
      </div>
    </div>
  )
}

DeleteItens.propTypes = {
  deletedList: PropTypes.arrayOf(PropTypes.string),
  setDeletedList: PropTypes.func.isRequired,
}
DeleteItens.defaultProps = {
  deletedList: [],
}

export default DeleteItens
