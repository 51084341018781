/* eslint-disable react/no-array-index-key */
import { Button, SearchBar, WorkspaceCard, ConfirmationModal } from 'components'

import { BaseLayout } from 'templates'
import { Loader } from 'semantic-ui-react'
import React from 'react'
import { useWorkspace, useLogin } from 'store'
import HomeProviders from './HomeProviders'
import styles from './Home.module.scss'

const Home = () => {
  const {
    isLoadingHome,
    getWorkspaces,
    deleteWorkspace,
    editWorkspace,
    newWorkspace,
    openModalWorkspace,
    closeModal,
    getExcludeConfirmation,
  } = useWorkspace()
  const { logout } = useLogin()

  const [search, setSearch] = React.useState('')

  const { title, text, show } = getExcludeConfirmation()
  return (
    <BaseLayout showLogout handleLogout={logout}>
      <div className={styles.home}>
        <p className={styles.title}>Área de trabalho</p>
        <p className={styles.subTitle}>Crie, edite e exclua workspaces</p>
        <div className={styles.row}>
          <Button text="criar novo workspace" onClick={newWorkspace} />
          <div className={styles.search}>
            <SearchBar search={search} setSearch={value => setSearch(value)} />
          </div>
        </div>
        {isLoadingHome() ? (
          <Loader active />
        ) : (
          <div className={styles.workspaces}>
            {getWorkspaces()
              .filter(({ name }) =>
                name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
              )
              .map(({ id, name, description }, index) => (
                <WorkspaceCard
                  title={name}
                  description={description}
                  editClick={() => editWorkspace(id)}
                  deleteClick={() => openModalWorkspace(id)}
                  key={index}
                />
              ))
              .reverse()}
          </div>
        )}
      </div>
      <ConfirmationModal
        title={title}
        text={text}
        show={show}
        submit={() => deleteWorkspace()}
        cancel={() => closeModal()}
      />
    </BaseLayout>
  )
}

const HomeWithProvider = () => (
  <HomeProviders>
    <Home />
  </HomeProviders>
)

export default HomeWithProvider
