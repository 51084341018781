import * as providers from './provider'
import { parseProducts } from './parsers'

export default setProducts => {
  const fetchProducts = async () => {
    const [err, res] = await providers.getProducts()

    if (err) {
      return
    }
    const products = res.data
    setProducts(prev => ({ ...prev, products: parseProducts(products) }))
  }

  return { fetchProducts }
}
