import * as providers from './provider'
import { parseTestPeriod } from './parsers'

export default setTestPeriod => {
  const fetchTestPeriod = async () => {
    const [err, res] = await providers.getTestPeriod()

    if (err) {
      return
    }
    const testPeriod = res.data
    setTestPeriod(prev => ({
      ...prev,
      testPeriod: parseTestPeriod(testPeriod),
    }))
  }

  return { fetchTestPeriod }
}
