export const initialParameters = {
  form: {
    dailyNumbers: '4',
    exclude: [],
    healthInsurance: 'Todos',
    minAge: '0',
    readjustment: '0%',
    stopLoss: '999999',
    surgicalCare: true,
    testDate: '2018',
    trainingDate: '2016 a 2018',
  },
  itens: {
    groups: [],
    sectors: [],
    enabledSectors: [],
    include: [],
    unMarkedGroups: [],
  },
  features: [],
}
