import React from 'react'
import PropTypes from 'prop-types'

import { Menu } from 'semantic-ui-react'
import styles from './TabComponent.module.scss'

const TabComponent = ({ values, activeTab, setActiveTab }) => {
  const showSegment = () => {
    const tab = values.length && values.find(value => value.id === activeTab)
    return tab ? tab.component : null
  }
  return (
    <div className={styles.tabComponent}>
      <Menu pointing secondary>
        {values.map(item => (
          <Menu.Item
            className={styles.tabTitle}
            key={item.id}
            name={item.title}
            active={activeTab === item.id}
            onClick={() => setActiveTab(item.id)}
          />
        ))}
      </Menu>
      <div className={styles.container}>{showSegment()}</div>
    </div>
  )
}

TabComponent.propTypes = {
  values: PropTypes.arrayOf(PropTypes.object),
  activeTab: PropTypes.number,
  setActiveTab: PropTypes.func.isRequired,
}

TabComponent.defaultProps = {
  values: [],
  activeTab: 0,
}

export default TabComponent
