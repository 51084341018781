import { useState, useEffect } from 'react'
import useCreateStore from '../../lib/useCreateStore'
import testPeriodActions from './actions'
import testPeriodSelectors from './selectors'

const initialState = {
  testPeriod: [],
}

const TestPeriodStore = useCreateStore(() => {
  const [$testPeriod, setTestPeriod] = useState(initialState)
  const actions = testPeriodActions(setTestPeriod)
  const selectors = testPeriodSelectors($testPeriod)

  useEffect(() => {
    const fetch = async () => {
      await actions.fetchTestPeriod()
    }
    fetch()
  }, []) // eslint-disable-line

  return { $testPeriod, ...actions, ...selectors }
})

export const useTestPeriod = () => TestPeriodStore()
export const TestPeriodContext = TestPeriodStore.Context
export const TestPeriodProvider = TestPeriodStore.Provider
