import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from 'components'
import styles from './WorkspaceCard.module.scss'

const WorkspaceCard = ({
  title,
  date,
  description,
  editClick,
  deleteClick,
}) => {
  return (
    <div className={styles.workspace}>
      <button type="button" className={styles.card} onClick={editClick}>
        <p className={styles.title}>{title}</p>
        <p className={styles.date}>{date}</p>
        <p className={styles.description}>{description}</p>
      </button>
      <button
        type="button"
        className={`${styles.icon} ${styles.iconButton}`}
        onClick={deleteClick}
      >
        <Icon type="garbage" />
      </button>
    </div>
  )
}

WorkspaceCard.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  editClick: PropTypes.func.isRequired,
  deleteClick: PropTypes.func.isRequired,
}

WorkspaceCard.defaultProps = {
  title: '',
  date: '',
  description: '',
}

export default WorkspaceCard
