import React from 'react'
import PropTypes from 'prop-types'

import { LoginProvider } from 'store'

const LoginProviders = ({ children }) => (
  <LoginProvider>{children}</LoginProvider>
)

LoginProviders.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LoginProviders
