export default $login => {
  const getUsername = () => $login.username
  const getToken = () => $login.token
  const getStep = () => $login.step
  const getCredentialsToken = () => $login.credentials
  const isLoadingLogin = () => $login.loadingLogin

  return {
    getUsername,
    getToken,
    getStep,
    getCredentialsToken,
    isLoadingLogin,
  }
}
