/* eslint-disable react/no-array-index-key */
import { CheckboxInput, Dropdown } from 'components'

import React from 'react'
import { useRule } from 'store'
import styles from './ItensContainer.module.scss'

const ItensContainer = () => {
  const [search, setSearch] = React.useState('')

  const {
    getGroups,
    getSectors,
    getEnabledSectors,
    getInclude,
    getUnMarkedGroups,
    createEnabledSelectors,
    unmarkInclude,
    unMarkSectorGroup,
  } = useRule()

  // eslint-disable-next-line no-unused-vars
  const mapStateToRequest = () => ({
    dataset: {
      dict_setor: getUnMarkedGroups()
        .filter(unmarkedGroup => {
          const group = getInclude().find(
            include => include.id_group === unmarkedGroup.groupId
          )
          return group && group.isChecked
        })
        .reduce(
          (dictSetor, excludeObj) => ({
            ...dictSetor,
            [excludeObj.sector]: [
              ...(dictSetor[excludeObj.sector] || []),
              excludeObj.groupId,
            ],
          }),
          {}
        ),
    },
  })

  return (
    <div>
      <div className={styles.headerGroups}>
        <span className={styles.label} />
        <div className={styles.containerItems}>
          {getGroups().map(item => (
            <div
              className={`${styles.item} ${!item.active && styles.disabled}`}
              key={item.id}
            >
              <p className={styles.itemText}>{item.label}</p>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.header}>
        <p className={styles.label}>Todos</p>
        <div className={styles.containerItems}>
          {getInclude().map((item, index) => (
            <div className={styles.item} key={index}>
              <CheckboxInput
                disabled={!item.active}
                isChecked={item.isChecked}
                onClick={() => {
                  if (item.active) {
                    unmarkInclude(index)
                  }
                }}
              />
            </div>
          ))}
        </div>
      </div>

      {getEnabledSectors().map((sector, secIndex) => (
        <div className={styles.header} key={secIndex}>
          <p className={styles.label}>{sector}</p>
          <div className={styles.containerItems}>
            {getGroups().map((group, index) => {
              const disabled = !getInclude()[index].isChecked
              const isExcluded = getUnMarkedGroups().some(
                unmarkedGroup =>
                  unmarkedGroup.sector === sector &&
                  unmarkedGroup.groupId === group.id
              )

              return (
                <div className={styles.item} key={group.id}>
                  <CheckboxInput
                    disabled={disabled}
                    onClick={() => {
                      // eslint-disable-next-line no-unused-expressions
                      !disabled && unMarkSectorGroup(sector, group.id)
                    }}
                    isChecked={!disabled && !isExcluded}
                  />
                </div>
              )
            })}
          </div>
        </div>
      ))}

      <div className={styles.headerSector}>
        <Dropdown
          allowEmpty
          values={getSectors().filter(
            item => !getEnabledSectors().includes(item.value)
          )}
          search={search}
          setSearch={value => setSearch(value)}
          placeholder="Digite o setor"
          addItem={value => {
            return [createEnabledSelectors(value), setSearch('')]
          }}
        />
      </div>
    </div>
  )
}

export default ItensContainer
