import map from 'lodash/map'

export const parseCovenants = covenants => {
  const covenantsAsArray = map(covenants.convenios, (item, key) => ({
    name: item,
    value: key,
  }))

  return covenantsAsArray
}
