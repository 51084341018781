import React from 'react'
import PropTypes from 'prop-types'

const MyComponent = ({ sampleNum, sampleText }) => {
  return (
    <div>
      {sampleNum}
      {sampleText}
    </div>
  )
}

MyComponent.propTypes = {
  sampleNum: PropTypes.number.isRequired,
  sampleText: PropTypes.string.isRequired,
}

export default React.memo(MyComponent)
