import homePt from 'pages/Home/locales/default.json'
import homeEn from 'pages/Home/locales/default.en.json'

import pt from './default.json'
import en from './default.en.json'

pt['pt-BR'].home = homePt['pt-BR']

en.en.home = homeEn.en

export { pt, en }
