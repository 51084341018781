import { useState } from 'react'

import useCreateStore from '../../lib/useCreateStore'
import loginActions from './actions'
import loginSelectors from './selectors'

const initialState = {
  loadingLogin: false,
  username: '',
  token: '',
  step: 0,
  cognitoUser: null,
}

const LoginStore = useCreateStore(() => {
  const [$login, setLogin] = useState(initialState)
  const actions = loginActions(setLogin, $login)
  const selectors = loginSelectors($login)

  return { $login, ...actions, ...selectors }
})

export const useLogin = () => LoginStore()
export const LoginContext = LoginStore.Context
export const LoginProvider = LoginStore.Provider
