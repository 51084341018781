import React from 'react'
import PropTypes from 'prop-types'

import {
  CovenantsProvider,
  TestPeriodProvider,
  TrainingPeriodProvider,
  ProductsProvider,
  RuleProvider,
} from 'store'

const RuleProviders = ({ children }) => (
  <RuleProvider>
    <ProductsProvider>
      <TrainingPeriodProvider>
        <TestPeriodProvider>
          <CovenantsProvider>{children}</CovenantsProvider>
        </TestPeriodProvider>
      </TrainingPeriodProvider>
    </ProductsProvider>
  </RuleProvider>
)

RuleProviders.propTypes = {
  children: PropTypes.node.isRequired,
}

export default RuleProviders
