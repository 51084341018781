/* eslint-disable react/no-array-index-key */
import React from 'react'

import { BaseLayout } from 'templates'
import {
  TextInput,
  TextArea,
  Button,
  RuleCard,
  ConfirmationModal,
  DeviationGraph,
} from 'components'
import { Loader } from 'semantic-ui-react'

import { useWorkspace } from 'store'
import WorkspaceProviders from './WorkspaceProviders'

import styles from './Workspace.module.scss'

const Workspace = () => {
  const {
    isLoadingWorkspace,
    getCurrentWorkspace,
    changeValue,
    getRules,
    makeCopy,
    editRule,
    deleteRule,
    createRule,
    openModalRule,
    closeModal,
    getExcludeConfirmation,
    getRulesDeviationData,
  } = useWorkspace()
  const { name, description } = getCurrentWorkspace()

  const { title, text, show } = getExcludeConfirmation()

  return (
    <BaseLayout>
      {isLoadingWorkspace() ? (
        <Loader active />
      ) : (
        <div className={styles.page}>
          <div className={styles.leftSide}>
            <TextInput
              text={name}
              onChange={value => changeValue('name', value)}
            />
            <p className={styles.smallText}>Adicionada em 09 julho 2019</p>

            <p className={styles.description}>Descrição</p>
            <TextArea
              text={description}
              onChange={value => changeValue('description', value)}
            />
            <Button text="adicionar regra" onClick={createRule} />
            {getRules().map(({ id, name: ruleName }, index) => (
              <RuleCard
                text={ruleName}
                copyClick={() => makeCopy(index)}
                editClick={() => editRule(id)}
                garbageClick={() => openModalRule(id)}
                key={index}
              />
            ))}
          </div>
          {!!getRules().length && (
            <div className={styles.rightSide}>
              <p className={styles.title}>Regras</p>
              <DeviationGraph data={getRulesDeviationData()} big />
            </div>
          )}
        </div>
      )}
      <ConfirmationModal
        title={title}
        text={text}
        show={show}
        submit={() => deleteRule()}
        cancel={() => closeModal()}
      />
    </BaseLayout>
  )
}

const WorkspaceWithProviders = () => (
  <WorkspaceProviders>
    <Workspace />
  </WorkspaceProviders>
)

export default WorkspaceWithProviders
