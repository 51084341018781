import React from 'react'

const Copy = () => (
  <svg
    width="16"
    height="16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fill="url(#pattern1)" fillOpacity=".8" d="M0 0h16v16H0z" />
    <defs>
      <pattern
        id="pattern1"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use xlinkHref="#copy" transform="scale(.01563)" />
      </pattern>
      <image
        id="copy"
        width="64"
        height="64"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfjBgsUDhp8i251AAAB1UlEQVRo3u2ZzysEYRjHP8suLnJYEa4OisPu0Z+g5KIUJ5EcnOQvkOTgIslJS0nOpEhxEQnJRQ6iJIfdpaTN793XYV/b7JrsO++M3S3vdw4zvTPP+/3M8877vPUOqMnPCKe8ILSPJLfM06Tol6MKNl1YW48YIR2AKY/sBYIrKq1d+xTsK4lTDbyywr1eCoEAXTQD0Meqs9CwZB/TNk+rnk8EghlrY5lCYAIBwI5LgCgPANRYG/0KgZeM0sMaZy4BSkBRBIJFa5PKEPypDMDPj7CCfjppdNHnGxcscKgX3MiZJ/UuxaRN7zYfYbZ8HHhYdAdUALKHoIN2ABKc8Kk9BEHCAIyzRMpZ6LRcOFu1zdOalTloyZ+B7FlQC0Ccc5cA30W7Lv+jdtPQYdpsJORZYa0teh0wAAbAABgAA2AADIABMAAGwAAYAANgAAoLUKbU9GcKEQQgVgyAbrbYl/sFu9YbKnvF7lXPKgF5vcd24TPgp1xendKT2T8pYAbuGGKQOOss85HLVhhFiNjf+Gd1wACUPsATALUqW6y/qk2eH/M/mj0NjwAIcMA679r2DfTK17lwGlrFtYf/CyZ06MPEPLLfyFR/h2pijhuSLqyfOWY4U/3z6AtzfXHIAOuU4QAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOS0wNi0xMVQxODoxNDoyNiswMjowMOSV+6MAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTktMDYtMTFUMTg6MTQ6MjYrMDI6MDCVyEMfAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg=="
      />
    </defs>
  </svg>
)

export default Copy
