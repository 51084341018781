import React from 'react'

import styles from './DeviationTooltip.module.scss'

const DeviationTooltip = ({ payload }) => {
  const data = payload[0]

  return (
    data && (
      <div className={styles.tooltip}>
        <p>
          <b>Valor inicial: </b>
          {data.value[0]}
        </p>
        <p>
          <b>Valor médio: </b>
          {data.payload.mean}
        </p>
        <p>
          <b>Valor final: </b>
          {data.value[1]}
        </p>
      </div>
    )
  )
}

export default DeviationTooltip
