import React from 'react'
import PropTypes from 'prop-types'
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  ReferenceLine,
  Label,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import { DeviationTooltip } from 'components'

const DeviationGraph = ({ data, clickHandler, selected, big }) => {
  const dataMin =
    -1 *
    data.reduce(
      (value, element) =>
        value > Math.max(...element.value) ? value : Math.max(...element.value),
      0
    )

  const getSelectedFill = name => {
    return name === selected
      ? 'rgba(70, 70, 70, 0.8)'
      : 'rgba(196, 196, 196, 0.8)'
  }

  const getHeight = () => {
    if (data.length === 1) {
      return big ? 110 : 90
    }
    return data.length * (big ? 80 : 60)
  }

  return (
    <ResponsiveContainer width="100%" height={getHeight()}>
      <BarChart layout="vertical" margin={{ top: 30 }} data={data} barGap={0}>
        <Bar
          style={{ cursor: 'pointer' }}
          dataKey="value"
          barSize={big ? 35 : 20}
          radius={12}
          onClick={value => clickHandler(value)}
        >
          {data.map(element => (
            <Cell key={element.name} fill={getSelectedFill(element.name)} />
          ))}
        </Bar>
        <Tooltip cursor={false} content={DeviationTooltip} />
        <XAxis type="number" domain={[dataMin, 'dataMax']} />
        <YAxis
          width={150}
          tickLine={false}
          type="category"
          interval={0}
          axisLine={false}
          dataKey="name"
        />
        <ReferenceLine x={0} stroke="#858585">
          <Label value="0" offset={10} position="top" />
        </ReferenceLine>
      </BarChart>
    </ResponsiveContainer>
  )
}

DeviationGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  clickHandler: PropTypes.func,
  selected: PropTypes.string,
  big: PropTypes.bool,
}

DeviationGraph.defaultProps = {
  data: [],
  clickHandler: () => {},
  selected: '',
  big: false,
}

export default DeviationGraph
