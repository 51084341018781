import React, { useLayoutEffect, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import each from 'lodash/each'

import { createGraph, addNode, addEdge, renderCluster } from 'lib/diagrams'
import { toMoney } from 'lib/utils'
import ReactTooltip from 'react-tooltip'
import * as styles from './DecisionTree.module.scss'

const DecisionTree = ({ data }) => {
  const [result, setResult] = useState([])

  useEffect(() => {
    const order = []
    const reorder = leaf => {
      if (leaf.children_ids) {
        const [left, right] = leaf.children_ids
        reorder(data[left])
        reorder(data[right])
      } else {
        order.push(leaf)
      }
    }
    reorder(data[0])
    setResult(order)
  }, [data, result])

  useLayoutEffect(() => {
    const g = createGraph()

    const getNodeName = (node, id) => {
      let name = node.feature

      if (!name) {
        name = `leaf_${id}`
      }

      return name
    }

    each(data, node => {
      const { id, hasChildren } = node

      const mappedNode = {
        id,
        isLeaf: !hasChildren,
        label: getNodeName(node, id),
      }

      addNode(g, mappedNode)

      each(node.children_ids, (childrenId, index) => {
        const label = index % 2 === 0 ? 'Sim ' : 'Não'
        addEdge(g, id, childrenId, { label })
      })
    })

    renderCluster(g, data)
  }, [data])

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  return (
    <table className={styles.row}>
      <tbody>
        <tr>
          <td>
            <ReactTooltip />
            <svg id="decisionTree" />
          </td>
          <td>
            {result.map(element => (
              <div className={styles.divLabel} key={element.id}>
                <div>
                  <b>Valor:</b>
                  {` ${toMoney(element.value)}`}
                </div>
                <div>
                  <b>Casos:</b>
                  {` ${element.n_samples}`}
                </div>
                <div>
                  <b>Total:</b>
                  {` ${toMoney(element.value * element.n_samples)}`}
                </div>
              </div>
            ))}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

DecisionTree.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      feature: PropTypes.string,
      value: PropTypes.number.isRequired,
      n_samples: PropTypes.number.isRequired,
      children_ids: PropTypes.arrayOf(PropTypes.number),
    })
  ).isRequired,
}

export default React.memo(DecisionTree)
