import { HomePage, RulePage, WorkspacePage, LoginPage } from 'pages'
import { Redirect, Router } from '@reach/router'

import React from 'react'

const RedirectPage = () => <Redirect to="/" />

function App() {
  return (
    <Router>
      <RedirectPage path="*" exact />
      <HomePage path="/" />
      <WorkspacePage path="/workspace/:workspace-id" />
      <RulePage path="/rule/:workspace-id/:rule-id" />
      <LoginPage path="/login" />
    </Router>
  )
}

export default () => <App />
