/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'components'

import styles from './TextArea.module.scss'

const TextArea = ({ text, onChange, rows, placeholder }) => {
  const inputRef = useRef()
  const focusInput = () => {
    inputRef.current.focus()
  }
  return (
    <div className={styles.row}>
      <textarea
        ref={inputRef}
        className={styles.input}
        placeholder={placeholder}
        type="text"
        onChange={e => onChange(e.target.value)}
        value={text}
        rows={rows}
      />
      <i className={styles.iconDisplay} onClick={() => focusInput()}>
        <Icon type="edit" />
      </i>
    </div>
  )
}

TextArea.propTypes = {
  text: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  rows: PropTypes.string,
  placeholder: PropTypes.string,
}

TextArea.defaultProps = {
  text: '',
  rows: '2',
  placeholder: 'Adicione uma breve descrição...',
}

export default TextArea
