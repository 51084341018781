import { MEAN_SHIFTER } from 'utils/constants'
import { parseTreeNodes, roundToMilions } from './parsers'

export default $rule => {
  const z = MEAN_SHIFTER

  const getRule = () => $rule
  const isLoading = () => $rule.loading
  const getErrorMessage = () => $rule.errorMessage

  // primarySimulation
  const getHealthInsurance = () =>
    $rule.parameters.form && $rule.parameters.form.healthInsurance
  const getDailyNumbers = () =>
    $rule.parameters.form && $rule.parameters.form.dailyNumbers
  const getTrainingDate = () =>
    $rule.parameters.form && $rule.parameters.form.trainingDate
  const getTestDate = () =>
    $rule.parameters.form && $rule.parameters.form.testDate
  const getReadjustment = () =>
    $rule.parameters.form && $rule.parameters.form.readjustment
  const getStopLoss = () =>
    $rule.parameters.form && $rule.parameters.form.stopLoss
  const getMinAge = () => $rule.parameters.form && $rule.parameters.form.minAge
  const getSurgicalCare = () =>
    $rule.parameters.form && $rule.parameters.form.surgicalCare
  const getExclude = () =>
    $rule.parameters.form && $rule.parameters.form.exclude

  // features
  const getFeatures = () => $rule.parameters.features
  const getCheckedFeatures = () =>
    $rule.parameters.features &&
    $rule.parameters.features.filter(x => x.isChecked).map(x => x.label)

  // itens
  const getGroups = () =>
    $rule.parameters.itens && $rule.parameters.itens.groups
  const getSectors = () =>
    $rule.parameters.itens && $rule.parameters.itens.sectors
  const getEnabledSectors = () =>
    $rule.parameters.itens && $rule.parameters.itens.enabledSectors
  const getInclude = () =>
    $rule.parameters.itens && $rule.parameters.itens.include
  const getUnMarkedGroups = () =>
    $rule.parameters.itens && $rule.parameters.itens.unMarkedGroups
  const getIncludeDictionary = () =>
    $rule.parameters.itens &&
    $rule.parameters.itens.include
      .filter(x => x.isChecked)
      .reduce((accumulator, item) => {
        return Object.assign({}, accumulator, { [item.name]: item.label })
      }, {})
  const getAttributes = () => $rule.attributes

  // graphs
  const getSelectedLeafId = () => $rule.selectedLeafId
  const getSelectedLeafName = () => $rule.selectedLeafName
  const getFeaturesVariabilityData = () => {
    const { selectedLeafId, attributes, parameters } = $rule
    const { features } = parameters
    const { leaf_analysis: leafAnalysis } = attributes

    if (!leafAnalysis) {
      return []
    }

    const result = Object.keys(leafAnalysis).map(element => {
      if (selectedLeafId === 'total') {
        return {
          [element]:
            Object.values(leafAnalysis[element]).reduce(
              (total, num) => total + num,
              0
            ) / Object.values(leafAnalysis[element]).length,
        }
      }
      return {
        [element]: leafAnalysis[element][selectedLeafId],
      }
    })

    // filter not selected features
    const checkedFeatures = features.filter(x => x.isChecked)

    return result.filter(x => {
      return checkedFeatures.some(y => {
        return y.name === Object.keys(x)[0]
      })
    })
  }

  const getDeviationData = () => {
    const { metrics } = $rule.attributes

    if (!metrics) {
      return []
    }

    const result = Object.entries(metrics)
      .map(element => ({
        name: element[0],
        value: [
          roundToMilions(element[1].mean - z * element[1].std),
          roundToMilions(element[1].mean + z * element[1].std),
        ],
        mean: roundToMilions(element[1].mean),
      }))
      .reverse()
    return result
  }

  const getDecisionTreeData = () => {
    const { attributes } = $rule
    if (!attributes || !attributes.treeviz) {
      return []
    }
    return parseTreeNodes(attributes.treeviz)
  }

  return {
    getRule,
    isLoading,
    getHealthInsurance,
    getDailyNumbers,
    getTrainingDate,
    getTestDate,
    getReadjustment,
    getStopLoss,
    getMinAge,
    getSurgicalCare,
    getExclude,
    getFeatures,
    getCheckedFeatures,
    getGroups,
    getSectors,
    getEnabledSectors,
    getInclude,
    getUnMarkedGroups,
    getIncludeDictionary,
    getFeaturesVariabilityData,
    getSelectedLeafId,
    getSelectedLeafName,
    getErrorMessage,
    getDeviationData,
    getAttributes,
    getDecisionTreeData,
  }
}
