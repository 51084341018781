/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'components'

import styles from './TextInput.module.scss'

const TextInput = ({ text, onChange, placeholder }) => {
  const inputRef = useRef()
  const focusInput = () => {
    inputRef.current.focus()
  }

  return (
    <div className={styles.row}>
      <input
        ref={inputRef}
        className={styles.input}
        type="text"
        onChange={e => onChange(e.target.value)}
        value={text}
        placeholder={placeholder}
      />
      <i className={styles.iconDisplay} onClick={() => focusInput()}>
        <Icon type="edit" />
      </i>
    </div>
  )
}

TextInput.propTypes = {
  text: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

TextInput.defaultProps = {
  text: '',
  placeholder: '',
}

export default TextInput
