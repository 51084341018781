import React from 'react'
import PropTypes from 'prop-types'

import styles from './Input.module.scss'

const Input = ({ text, onChange, placeholder, type, onEnterPress }) => {
  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      onEnterPress()
    }
  }
  return (
    <div className={styles.row}>
      <input
        className={styles.input}
        type={type}
        onChange={e => onChange(e.target.value)}
        onKeyPress={handleKeyPress}
        value={text}
        placeholder={placeholder}
      />
    </div>
  )
}

Input.propTypes = {
  text: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onEnterPress: PropTypes.func,
}

Input.defaultProps = {
  text: '',
  placeholder: '',
  type: 'text',
  onEnterPress: () => {},
}

export default Input
