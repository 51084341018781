import React from 'react'

const Logout = () => (
  <svg
    width="16"
    height="16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fill="url(#pattern4)" fillOpacity=".6" d="M0 0h16v16H0z" />
    <defs>
      <pattern
        id="pattern4"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use xlinkHref="#image0" transform="scale(.01563)" />
      </pattern>
      <image
        id="image0"
        width="64"
        height="64"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfjBgsUEiRbnS6DAAACUUlEQVRo3u2ZO09UQRSAv7usQGGABAoUspDw0gYoiIkUVvgDLOwUG15ZDNT4WBMKBJTKZOEHaAHWJlrakBCCCXQgDwXkEQs0ISSwuNdCubtzxV3nzs6MIZxpZnJnzvnm3HvPnJlxOJHL3KWNSvKRkx2GeCM55g8JEeMAN2BJ0KxmPo+pwMZ/lUcqc4dBbit6cEdlcC1HivOfoTC4+TCdXPBan3jFuqSGLd6RUPHAvDeTRYpUFAWVbx5Anw3zIYq9+oYdAMtyDmAdIKxJbwndlPKa2exdUxHtVs7MX+QjLi7HdNoBuOfpTNKTuauebyAV3B3i9Jr3QCnbaXqT9JsGgGa+CivmgGkAaPIhPDQNAFeFF+Hy2DQAXGFLQHhiGgAa+CIgDJsGgHo2MyPoBoA6NgSEUdMAUM2agPDcNABUsSogjJkGgAgrAsI4jlkAiLAsIEzgyOYDLbRTroCwS01aq5sf3JcBaOV9jhOYKOsyy3FUQ/7ULwPg5tw8JGUAxjnOOcALGadOc13xI6ygVWjHGbX+G5oDqPIForjZQGQ5FPsXo2fpD/UD+DOCEfGxboCsOdGZTsn8SWnstE7m0vK/HGee0Y2J5a1Zme3NaYdgPuP2XM8RzVHa9KJMZO6s54hmiX88otHjgX2u0UUZk8xl72wuHzhVrJ8TngP8BwDfvXrEDkLqymbJxpVNmLc0/q7XscBL85dWNRwK65Z8Ubq2y2OPQm4oebGCz3wIOjgExJhSAkBpuwaAwwP2A7+CBE0qpk/kEne4SSUFkhq2eapyff8T5zNDbNXmuWQAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTktMDYtMTFUMTg6MTg6MzYrMDI6MDAyAxuzAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE5LTA2LTExVDE4OjE4OjM2KzAyOjAwQ16jDwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII="
      />
    </defs>
  </svg>
)

export default Logout
