import React from 'react'
import PropTypes from 'prop-types'

import { WorkspaceProvider, LoginProvider } from 'store'

const HomeProviders = ({ children }) => (
  <WorkspaceProvider>
    <LoginProvider>{children}</LoginProvider>
  </WorkspaceProvider>
)

HomeProviders.propTypes = {
  children: PropTypes.node.isRequired,
}

export default HomeProviders
