import orderBy from 'lodash/orderBy'

// eslint-disable-next-line max-params
export const getDropDownKey = (list, text, key = 'text', returnKey = 'key') => {
  const obj = list.find(x => x[key] === text)

  return obj && obj[returnKey]
}

export const dropDownToDictionary = list =>
  list.reduce(
    (obj, currentValue) => ({
      ...obj,
      [currentValue.name]: currentValue.var_name,
    }),
    {}
  )

export const getCharacterFromIndex = index => String.fromCharCode(97 + index)

const formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2,
})

export const toMoney = value => formatter.format(value)

export const parseToJson = data => {
  return typeof data === 'string'
    ? JSON.parse(data.replace(/\bNaN\b/g, '0'))
    : data
}

export const sortAlphabetical = (value, key = null) => {
  return orderBy(value, [
    item => (key ? item[key].toLowerCase() : item.toLowerCase()),
  ])
}
