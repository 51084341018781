import React from 'react'

const Garbage = () => (
  <svg
    width="16"
    height="16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      id="Garbage"
      fill="url(#pattern3)"
      fillOpacity=".7"
      d="M0 0h16v16H0z"
    />
    <defs>
      <pattern
        id="pattern3"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use xlinkHref="#garbage" transform="scale(.01563)" />
      </pattern>
      <image
        id="garbage"
        width="64"
        height="64"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfjBgsUEQal0DykAAADYUlEQVRo3u3ZS2xUdRTH8c/MtEzagU67qG2pQikxPhILmGCwmvoKKkai1uBaA4ludcXKxAWJO/fGkNYYSTSasJA0wEZJSNSFQE0MtaWAUKqEh50+nNI748I20Gmn/be5CSHO+a/uP797zvf/nLnnEG5pr+g1aEqxTJsyqMcu6RV4DbZ2nxmVFymUBSiI5F3xqba4w7f4wnVR2dB3tsg1n2sOc1wVCLBPl3oJf/rZabkyqjodtmvS4Bl7HYgPoNWLmiX85qA+N8yU9dZgl3c8osXLDroSF0CH9aoVfOcrfyguoRyR0+Qh1dbbEgKQDAJok8YN/S4vGZ6iS/rdRNqmENdhALVSyJkQLauNjBtDSm2I69tL0ChbdkGaVCGlxcMBPlukUKWprHrG367OB9jsNe2qy7ywRS2ydnssAKBNFrW6ZMsobhly2Lk7u/YZDDrjcbVBe+fPQJVpeUmkJFFUUAhZw0BLSkqgIEJBfi7yHMARbbZaI2GDdkwYcj1GgHoPWovzLiqa9osjiwsT9isqOu35GMPzrFOKivZLlE7NXbYKwD0PkJK6ewBrPOmYo3ZYs3onoX9I6mTcNDWvb609nsYeAyV3Rq362Z+kmADafKDdcd+6OO/dDaqxscTLRt1eMOQT5+MC2OFVbbLOzANIzK5/quRy2exNnYadDAEI2wOtaiQ0qwtS12mSUOv+EHEYQHJ2xIkg9ZwuyPc9fw9UACoAFYAKQAWgAlABqABUACoAFYD/C8CYCJOmg9TTphDF+XXc5wkdvtEfpP7Vl7qd0RcfwGUfq3NlLr87a9Hs89WSFPaIXkeNuRwfwIzfF+kdd0grDhmf13/LiJEgv4sAFEUiKcmyievblvejAzglv6w2LYVIVFpvWDgD/5i0Ttp9yzotmnQycKBN0pgoSfJY7BRcN4p622I8oimPa8DowvzzwiAXnEW9p2yNDWCbTlmcdWEhW6lNa7FdjYx1Bl1bpkYUMvpHva9TxrivHS/ZsIvsgVE/eE6Xem+p0uNSUKWoXPCMB7ytW0bkJyeMlkoWy/rUed2HNkkq+Msxw6ZWNQ8JNdrt1CipYNhHDofdjjR6z7B8bAWavHPe1bgS+oyXnJBbpli9XPuvmJ3zvZ0yK5/CjG69BuRWiVCQM6DHG0sF/xfu95k46kkIxAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOS0wNi0xMVQxODoxNzowNiswMjowME2HR90AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTktMDYtMTFUMTg6MTc6MDYrMDI6MDA82v9hAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg=="
      />
    </defs>
  </svg>
)

export default Garbage
