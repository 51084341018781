import React from 'react'
import PropTypes from 'prop-types'

import styles from './ConfirmationModal.module.scss'

const ConfirmationModal = ({ title, text, show, submit, cancel }) => {
  return (
    <div
      className={styles.modal}
      style={{
        pointerEvents: show ? 'all' : 'none',
        opacity: show ? '1' : '0',
      }}
    >
      <div className={styles.modalWrapper}>
        <div className={styles.modalHeader}>
          <h3>{title}</h3>
        </div>
        <div className={styles.modalBody}>
          <p>{text}</p>
        </div>
        <div className={styles.modalFooter}>
          <button type="button" className={styles.btnCancel} onClick={cancel}>
            Não
          </button>
          <button type="button" className={styles.btnContinue} onClick={submit}>
            Sim
          </button>
        </div>
      </div>
    </div>
  )
}

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  show: PropTypes.bool,
  submit: PropTypes.func,
  cancel: PropTypes.func,
}

ConfirmationModal.defaultProps = {
  title: '',
  text: '',
  show: false,
  submit: () => {},
  cancel: () => {},
}

export default ConfirmationModal
