import React from 'react'
import PropTypes from 'prop-types'

import { Checkbox } from 'semantic-ui-react'
import styles from './CheckboxInput.module.scss'

const CheckboxInput = ({ hasLabel, label, isChecked, onClick, disabled }) => (
  <div className={styles.item}>
    <Checkbox
      className={styles.checkbox}
      label={hasLabel ? label : null}
      checked={isChecked}
      onClick={onClick}
      disabled={disabled}
    />
  </div>
)

CheckboxInput.propTypes = {
  hasLabel: PropTypes.bool,
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

CheckboxInput.defaultProps = {
  hasLabel: false,
  label: '',
  isChecked: true,
  disabled: false,
}

export default CheckboxInput
