/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'

import styles from './VariabilityGraph.module.scss'

const VariabilityGraph = ({ data, title }) => {
  return (
    data &&
    data.length && (
      <div className={styles.componentBody}>
        <div className={styles.row}>
          <div className={styles.titleCol}>
            <p>{title}</p>
          </div>
          <div className={styles.paramsCol}>
            <span>0</span>
            <span>0,5</span>
            <span>1</span>
          </div>
        </div>
        {data.map((element, i) => (
          <div key={i} className={styles.row}>
            <div className={styles.labelCol}>
              <span className={styles.label}>
                {Object.entries(element)[0][0]}
              </span>
            </div>
            <div className={styles.lineCol}>
              <div className={styles.line} />
              <span
                title={Object.entries(element)[0][1]}
                className={styles.ellipse}
                style={{
                  marginLeft: `calc(${Object.entries(element)[0][1] *
                    100}% - 7px)`,
                }}
              />
            </div>
          </div>
        ))}
      </div>
    )
  )
}

VariabilityGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

VariabilityGraph.defaultProps = {
  data: [],
  title: '',
}

export default VariabilityGraph
