import React from 'react'
import PropTypes from 'prop-types'

import { WorkspaceProvider } from 'store'

const WorkspaceProviders = ({ children }) => (
  <WorkspaceProvider>{children}</WorkspaceProvider>
)

WorkspaceProviders.propTypes = {
  children: PropTypes.node.isRequired,
}

export default WorkspaceProviders
