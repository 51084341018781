import { useState, useEffect } from 'react'
import useCreateStore from '../../lib/useCreateStore'
import covenantsActions from './actions'
import covenantsSelectors from './selectors'

const initialState = {
  covenants: [],
}

const CovenantStore = useCreateStore(() => {
  const [$covenants, setCovenants] = useState(initialState)
  const actions = covenantsActions(setCovenants, $covenants)
  const selectors = covenantsSelectors($covenants)

  useEffect(() => {
    const fetch = async () => {
      await actions.fetchCovenants()
    }

    fetch()
  }, []) // eslint-disable-line

  return { $covenants, ...actions, ...selectors }
})

export const useCovenants = () => CovenantStore()
export const CovenantsContext = CovenantStore.Context
export const CovenantsProvider = CovenantStore.Provider
