import map from 'lodash/map'

export const parseTrainingPeriod = trainingtPeriod => {
  const trainingPeriodAsArray = map(
    trainingtPeriod.periodo_treino,
    ({ id, value }) => ({
      key: id,
      text: value,
    })
  )

  return trainingPeriodAsArray
}
