import React from 'react'
import PropTypes from 'prop-types'

import styles from './Button.module.scss'

const Button = ({ text, onClick, disabled }) => (
  <button
    type="button"
    className={`${styles.button} ${disabled && styles.disabled}`}
    onClick={onClick}
    disabled={disabled}
  >
    {text}
  </button>
)

Button.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

Button.defaultProps = {
  text: '',
  disabled: false,
}

export default Button
