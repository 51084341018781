import map from 'lodash/map'
import round from 'lodash/round'
import decodeURI from 'lib/decodeURI'

export const parseFeatures = features => {
  return Object.entries(features.features).map(element => {
    return {
      label: decodeURI(element[1].label),
      isChecked: true,
      description: decodeURI(element[1].desc),
      name: element[0],
    }
  })
}

export const parseGroups = groups =>
  map(groups.grupos, (element, key) => ({
    id: key,
    name: element.name,
    label: decodeURI(element.label),
    var_name: element.var_name,
    active: element.active,
  }))

export const parseSectors = sectors => {
  return map(sectors.setores, (item, key) => ({
    name: key,
    value: item,
  }))
}

export const parseInclude = groups => {
  const result = map(groups, (element, key) => ({
    groupId: key,
    name: element.name,
    label: element.label,
    var_name: element.var_name,
    isChecked: true,
    active: element.active,
  }))

  return result
}

export const parseTreeNodes = data =>
  data.map((element, index) => {
    const hasChildren = Boolean(
      element.children_ids && element.children_ids.length
    )

    return {
      id: index,
      hasChildren,
      ...element,
    }
  })

export const parsePercent = value => {
  return value ? parseFloat(value.toString()) / 100 : 0
}

export const roundToMilions = value => round(parseFloat(value / 1000000), 2)
