import { create } from 'apisauce'
import Cookies from 'js-cookie'

/* global window */

// const baseURL = 'http://localhost:5000'
const baseURL = 'https://2z3scqun30.execute-api.us-east-1.amazonaws.com/dev/'

const api = create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
})

api.addRequestTransform(request => {
  request.headers.Authorization = `Bearer ${Cookies.get('idToken')}`
})

api.addResponseTransform(async response => {
  if (
    (response.status === 401 || response.status == null) &&
    response.problem !== undefined
  ) {
    window.location.href = '/login'
  }
})

export default api

export const publicApi = create({
  baseURL: '/',
})
