import React from 'react'
import { TabComponent } from 'components'
import { FeaturesContainer, ItensContainer } from 'containers'

const values = [
  {
    id: 1,
    title: 'Features',
    component: <FeaturesContainer />,
  },
  {
    id: 2,
    title: 'Itens',
    component: <ItensContainer />,
  },
]

const TabContainer = () => {
  const [activeTab, setActiveTab] = React.useState(1)
  return (
    <TabComponent
      values={values}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  )
}

export default TabContainer
