import * as providers from './provider'
import { parseCovenants } from './parsers'

export default setCovenants => {
  const fetchCovenants = async () => {
    const [err, res] = await providers.getCovenants()

    if (err) {
      return
    }
    const covenants = res.data
    setCovenants(prev => ({ ...prev, covenants: parseCovenants(covenants) }))
  }

  return { fetchCovenants }
}
