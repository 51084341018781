import { useState, useEffect } from 'react'
import useCreateStore from '../../lib/useCreateStore'
import productsActions from './actions'
import productsSelectors from './selectors'

const initialState = {
  products: [],
}

const ProductsStore = useCreateStore(() => {
  const [$products, setProducts] = useState(initialState)
  const actions = productsActions(setProducts)
  const selectors = productsSelectors($products)

  useEffect(() => {
    const fetch = async () => {
      await actions.fetchProducts()
    }

    fetch()
  }, []) // eslint-disable-line

  return { $products, ...actions, ...selectors }
})

export const useProducts = () => ProductsStore()
export const ProductsContext = ProductsStore.Context
export const ProductsProvider = ProductsStore.Provider
