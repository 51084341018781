import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from 'components'
import styles from './RuleCard.module.scss'

const RuleCard = ({ text, copyClick, editClick, garbageClick }) => {
  return (
    <div className={styles.card}>
      <p className={styles.ruleText}>{text}</p>
      <div className={styles.row}>
        <button type="button" className={styles.icon} onClick={copyClick}>
          <Icon type="copy" />
        </button>
        <button type="button" className={styles.icon} onClick={editClick}>
          <Icon type="edit" />
        </button>
        <button type="button" className={styles.icon} onClick={garbageClick}>
          <Icon type="garbage" />
        </button>
      </div>
    </div>
  )
}

RuleCard.propTypes = {
  text: PropTypes.string,
  copyClick: PropTypes.func.isRequired,
  editClick: PropTypes.func.isRequired,
  garbageClick: PropTypes.func.isRequired,
}

RuleCard.defaultProps = {
  text: '',
}

export default RuleCard
