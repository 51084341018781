import React from 'react'

import { sortAlphabetical } from 'lib/utils'
import { CheckboxInput } from 'components'
import { useRule } from 'store'
import styles from './FeaturesContainer.module.scss'

const FeaturesContainer = () => {
  const { unMarkFeature, getFeatures } = useRule()

  const features = getFeatures().map((item, index) => ({
    id: index,
    ...item,
  }))
  const sortedFeatures = sortAlphabetical(features, 'label')

  return (
    <div className={styles.features}>
      {sortedFeatures.map(item => (
        <CheckboxInput
          key={item.id}
          label={item.label}
          isChecked={item.isChecked}
          onClick={() => {
            unMarkFeature(item.id)
          }}
          hasLabel
        />
      ))}
    </div>
  )
}

export default FeaturesContainer
