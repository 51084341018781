import { MEAN_SHIFTER } from 'utils/constants'
import { roundToMilions } from '../rule/parsers'

export default $workspaces => {
  const z = MEAN_SHIFTER

  const getWorkspaces = () => $workspaces.workspaces
  const isLoadingHome = () => $workspaces.loadingHome
  const isLoadingWorkspace = () => $workspaces.loadingWorkspace
  const getCurrentWorkspace = () => $workspaces.currentWorkspace
  const getErrorMessage = () => $workspaces.messageError
  const hasError = () => $workspaces.hasError
  const getRules = () => $workspaces.workspaceRules
  const getExcludeConfirmation = () =>
    $workspaces.excludeConfirmation ? $workspaces.excludeConfirmation : {}

  const getRulesDeviationData = () => {
    const { workspaceRules: rules } = $workspaces

    if (!rules) {
      return []
    }

    const rulesResult = rules.map(rule => {
      if (!rule.results) {
        return {
          name: rule.name,
          value: [0, 0],
          mean: 0,
        }
      }

      const total = (rule.results.metrics && rule.results.metrics.total) || {}

      const result = {
        name: rule.name,
        value: [
          roundToMilions(total.mean - z * total.std),
          roundToMilions(total.mean + z * total.std),
        ],
        mean: roundToMilions(total.mean),
      }
      return result
    })

    return rulesResult
  }

  return {
    getWorkspaces,
    isLoadingHome,
    isLoadingWorkspace,
    getCurrentWorkspace,
    getErrorMessage,
    hasError,
    getRules,
    getExcludeConfirmation,
    getRulesDeviationData,
  }
}
