const to = promise =>
  promise
    .then(res => {
      if (res.problem && !res.ok) {
        throw new Error(res.problem)
      }
      return [undefined, res]
    })
    .catch(err => [err, undefined])

export default to
