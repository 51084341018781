import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'

import { Icon } from 'components'
import styles from './Header.module.scss'

// TODO: implement  the logo icon  (logout)
const Header = ({ backRoute }) => {
  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <Link to={backRoute}>
          <Icon type="arrow" />
        </Link>
      </div>
      <Icon type="ellipse" />
    </header>
  )
}

Header.propTypes = {
  backRoute: PropTypes.string.isRequired,
}

export default React.memo(Header)
