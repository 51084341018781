import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from 'components'

import styles from './SearchBar.module.scss'

const SearchBar = ({ placeholder, search, setSearch }) => {
  return (
    <div className={styles.search}>
      <Icon type="magnifyingglass" />
      <input
        placeholder={placeholder}
        type="text"
        value={search}
        className={styles.input}
        onChange={e => setSearch(e.target.value)}
      />
    </div>
  )
}

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  search: PropTypes.string,
  setSearch: PropTypes.func.isRequired,
}

SearchBar.defaultProps = {
  placeholder: 'Pesquisar na área de trabalho...',
  search: '',
}

export default SearchBar
