import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'components'

import styles from './FixedDropdown.module.scss'

const FixedDropdown = ({ options, selectedValue, setSelectedValue }) => {
  const [listVisible, setListVisible] = React.useState(false)

  return (
    <div
      className={styles.component}
      onMouseLeave={() => setListVisible(false)}
    >
      <div className={styles.dropdown}>
        <input
          type="text"
          value={selectedValue || ''}
          className={styles.input}
          onMouseEnter={() => setListVisible(true)}
          disabled
        />
        <Icon type="calendarclock" />
      </div>
      <div>
        {listVisible && (
          <ul className={styles.list}>
            {options
              .filter(option => option.text !== selectedValue)
              .map(({ key, text }) => (
                <button
                  key={key}
                  type="button"
                  id={key}
                  className={styles.item}
                  onKeyPress={() => {}}
                  onClick={() => [
                    setSelectedValue(text),
                    setListVisible(false),
                  ]}
                >
                  {text}
                </button>
              ))}
          </ul>
        )}
      </div>
    </div>
  )
}

FixedDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  selectedValue: PropTypes.string,
  setSelectedValue: PropTypes.func.isRequired,
}

FixedDropdown.defaultProps = {
  options: [],
  selectedValue: '',
}

export default FixedDropdown
