import myApi, { publicApi } from 'lib/api'
import to from 'lib/to'

export const getWorkspacesData = (workspaceId, ruleId) =>
  to(myApi.get(`/workspaces/${workspaceId}/rules/${ruleId}`))

export const changeRule = (payload, workspaceId, ruleId) =>
  to(myApi.patch(`/workspaces/${workspaceId}/rules/${ruleId}`, payload))

export const saveRule = (payload, workspaceId, ruleId) =>
  to(myApi.post(`/workspaces/${workspaceId}/rules/${ruleId}`, payload))

export const simulateRule = (payload, callback) => {
  myApi.addResponseTransform(async response => {
    if (response.status === 504) {
      callback()
    }
  })
  return to(myApi.post('/simulate', payload))
}

export const getFeatures = () => to(publicApi.get('/features.json'))

export const getGroups = () => to(publicApi.get('/groups.json'))
export const getSectors = () => to(publicApi.get('/sectors.json'))
