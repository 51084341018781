import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js'

const userPool = new CognitoUserPool({
  UserPoolId: 'us-east-1_5l30Jydl0',
  ClientId: '6fsje7aoomspm1fsdsmvsfrsk6',
})

export const initiateAuth = (username, callback) => {
  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: userPool,
  })

  cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH')
  const auth = new AuthenticationDetails({
    Username: cognitoUser.getUsername(),
  })

  return new Promise((resolve, reject) => {
    cognitoUser.initiateAuth(auth, {
      onSuccess: result => resolve(result),
      onFailure: err => {
        callback()
        reject(new Error('Erro no envio do token.\n', JSON.stringify(err)))
      },
      customChallenge: () => resolve(cognitoUser),
    })
  })
}

export const answerCode = ({ username, token, cognitoUser }, callback) => {
  return new Promise((resolve, reject) => {
    if (!cognitoUser) {
      return reject(new Error('Por favor reinicie o processo de login'))
    }

    return cognitoUser.sendCustomChallengeAnswer(token, {
      onSuccess: result => {
        resolve(result)
        callback()
      },
      onFailure: () => {
        initiateAuth(username)
        reject(
          new Error('Token expirado. Foi enviado novo token no seu email.')
        )
        callback()
      },
      customChallenge: () => {
        reject(new Error('Código inválido. Confirme o token enviado.'))
        callback()
      },
    })
  })
}
