import * as providers from './provider'
import { parseTrainingPeriod } from './parsers'

export default setTrainingPeriod => {
  const fetchTrainingPeriod = async () => {
    const [err, res] = await providers.getTrainingPeriod()

    if (err) {
      return
    }
    const trainingPeriod = res.data
    setTrainingPeriod(prev => ({
      ...prev,
      trainingPeriod: parseTrainingPeriod(trainingPeriod),
    }))
  }

  return { fetchTrainingPeriod }
}
