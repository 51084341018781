import myApi from 'lib/api'
import to from 'lib/to'

export const getWorkspacesData = () => to(myApi.get('/workspaces'))
export const postWorkspace = payload => to(myApi.post('/workspaces', payload))
export const deleteWorkspace = id => to(myApi.delete(`/workspaces/${id}`))
export const getWorkspace = id => to(myApi.get(`/workspaces/${id}`))
export const getRules = id => to(myApi.get(`/workspaces/${id}/rules`))
export const postCopy = (workspaceId, payload) =>
  to(myApi.post(`/workspaces/${workspaceId}/rules`, payload))
export const deleteRule = (workspaceId, ruleId) =>
  to(myApi.delete(`/workspaces/${workspaceId}/rules/${ruleId}`))
export const changeRule = (payload, id) =>
  to(myApi.patch(`/workspaces/${id}`, payload))
export const postRule = (payload, id) =>
  to(myApi.post(`/workspaces/${id}/rules`, payload))
