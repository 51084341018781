import { navigate } from '@reach/router'
import Cookies from 'js-cookie'
import * as providers from './provider'

export default (setLogin, $login) => {
  const changeValue = async (key, value) => {
    setLogin(prev => ({
      ...prev,
      [key]: value,
    }))
  }

  const startLoading = () => {
    setLogin(prev => ({
      ...prev,
      loadingLogin: true,
    }))
  }

  const endLoading = () => {
    setLogin(prev => ({
      ...prev,
      loadingLogin: false,
    }))
  }

  const generateCognitoChallenge = async () => {
    const { username } = $login

    startLoading()
    const cognitoUser = await providers.initiateAuth(username, () =>
      endLoading()
    )

    changeValue('step', 1)
    changeValue('cognitoUser', cognitoUser)

    endLoading()
  }

  const generateLoginCredentials = async () => {
    startLoading()
    const credentials = await providers.answerCode($login, () => endLoading())

    Cookies.set('idToken', credentials.idToken.jwtToken)
    Cookies.set('refreshToken', credentials.refreshToken.token)
    Cookies.set('accessToken', credentials.accessToken.jwtToken)

    navigate('/')
  }

  const logout = async () => {
    const { cognitoUser } = $login

    if (cognitoUser) {
      cognitoUser.signOut()
    }

    changeValue('step', 0)
    changeValue('cognitoUser', null)

    Cookies.remove('idToken')
    Cookies.remove('refreshToken')
    Cookies.remove('accessToken')

    navigate('/login')
  }

  return {
    changeValue,
    generateCognitoChallenge,
    generateLoginCredentials,
    logout,
  }
}
