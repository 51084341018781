import { useState, useEffect } from 'react'
import split from 'lodash/split'
import { createHistory, navigate } from '@reach/router'

import useCreateStore from '../../lib/useCreateStore'
import workspaceActions from './actions'
import workspaceSelectors from './selectors'

const initialState = {
  loadingHome: true,
  loadingWorkspace: true,
  workspaces: [],
  currentWorkspace: {
    id: null,
    name: '',
    description: '',
    attributes: '',
  },
  workspaceRules: [],
  hasError: false,
  messageError: '',
  excludeConfirmation: {
    title: '',
    text: '',
    show: false,
    ruleId: null,
  },
}

const WorkspaceStore = useCreateStore(() => {
  const [$workspaces, setWorkspaces] = useState(initialState)
  const actions = workspaceActions(setWorkspaces, $workspaces)
  const selectors = workspaceSelectors($workspaces)

  // eslint-disable-next-line no-undef
  const history = createHistory(window)

  useEffect(() => {
    const itemId = split(history.location.pathname, '=', 2)

    const fetch = async () => {
      await actions.fetchWorkspaces()
      if (itemId.length === 2) {
        await actions.fetchWorks(itemId[1])
        await actions.fetchRules(itemId[1])
      } else {
        navigate(`/`)
      }
    }

    fetch()
  }, []) // eslint-disable-line

  return { $workspaces, ...actions, ...selectors }
})

export const useWorkspace = () => WorkspaceStore()
export const WorkspaceContext = WorkspaceStore.Context
export const WorkspaceProvider = WorkspaceStore.Provider
