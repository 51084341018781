import React from 'react'
import PropTypes from 'prop-types'
import styles from './Section.module.scss'

const Section = ({ title, children }) => {
  return (
    <div className={styles.container}>
      <h3>{title}</h3>
      <div>{children}</div>
    </div>
  )
}

Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element.isRequired,
}

Section.defaultProps = {
  title: '',
}

export default React.memo(Section)
