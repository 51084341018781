import React, { useState } from 'react'
import { Loader } from 'semantic-ui-react'

import { BaseLayout } from 'templates'
import { useRule, useTestPeriod, useTrainingPeriod, useCovenants } from 'store'
import { PrimarySimulationForm, TabContainer } from 'containers'
import {
  TextInput,
  Button,
  Section,
  DecisionTree,
  VariabilityGraph,
  DeviationGraph,
} from 'components'

import RuleProviders from './RuleProviders'
import styles from './Rule.module.scss'

const Rule = () => {
  const {
    getRule,
    changeValue,
    isLoading,
    simulate,
    getFeaturesVariabilityData,
    getSelectedLeafName,
    getErrorMessage,
    getDeviationData,
    changeSelectedLeaf,
    getSelectedLeafId,
    getDecisionTreeData,
    changeCanSimulate,
  } = useRule()
  const { name, workspaceId, attributes, canSimulate } = getRule()

  const { getTestPeriod } = useTestPeriod()
  const { getTrainingPeriod } = useTrainingPeriod()
  const { getCovenants } = useCovenants()

  const getSelectedDeviationLeaf = () => {
    const selectedLeafId = getSelectedLeafId()
    return selectedLeafId === 'total' ? 'total' : `leaf_${selectedLeafId}`
  }

  const [firstLoaded, setFirstLoaded] = useState(false)

  const makeSimulation = () => {
    changeCanSimulate(false)

    if (!firstLoaded) {
      setFirstLoaded(true)
    }

    simulate({
      testPeriod: getTestPeriod(),
      trainingPeriod: getTrainingPeriod(),
      convenants: getCovenants(),
    })
  }

  return (
    <BaseLayout backRoute={`/workspace/id=${workspaceId}`}>
      {isLoading() ? (
        <Loader active />
      ) : (
        <>
          <div className={styles.rulePage}>
            <div className={styles.contentLeft}>
              <TextInput
                text={name}
                onChange={value => changeValue('name', value)}
              />
              <PrimarySimulationForm />
              <div className={styles.simulation}>
                <Button
                  text="simular"
                  onClick={makeSimulation}
                  disabled={!canSimulate}
                />
                <span className={styles.errorMessage}>{getErrorMessage()}</span>
              </div>
            </div>
            <div className={styles.contentRight}>
              <TabContainer />
            </div>
          </div>
          {attributes && firstLoaded && (
            <>
              <Section title="Árvore">
                <DecisionTree data={getDecisionTreeData()} />
              </Section>
              <Section title="Risco (em milhões)">
                <DeviationGraph
                  data={getDeviationData()}
                  clickHandler={changeSelectedLeaf}
                  selected={getSelectedDeviationLeaf()}
                />
              </Section>
              {getSelectedLeafId() && (
                <Section title="Variabilidade">
                  <VariabilityGraph
                    data={getFeaturesVariabilityData()}
                    title={getSelectedLeafName()}
                  />
                </Section>
              )}
            </>
          )}
        </>
      )}
    </BaseLayout>
  )
}

const RuleWithProviders = () => (
  <RuleProviders>
    <Rule />
  </RuleProviders>
)
export default RuleWithProviders
