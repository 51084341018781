import React from 'react'

import { Input, Button } from 'components'

import { useLogin } from 'store'
import { Loader } from 'semantic-ui-react'
import styles from './Login.module.scss'

import logo from '../../assets/images/logo.jpeg'

import LoginProviders from './LoginProviders'

const Login = () => {
  const {
    isLoadingLogin,
    getUsername,
    getToken,
    getStep,
    generateCognitoChallenge,
    generateLoginCredentials,
    changeValue,
  } = useLogin()

  return (
    <div className={styles.page}>
      {isLoadingLogin() ? (
        <Loader active />
      ) : (
        <div className={styles.mainForm}>
          <img src={logo} alt={logo} className={styles.logo} />
          <p className={styles.title}>
            {getStep() === 0 ? (
              <span>To continue, enter your email.</span>
            ) : (
              <span>{`Verify the token sent to ${getUsername()}`}</span>
            )}
          </p>
          {getStep() === 0 ? (
            <div className={styles.row}>
              <Input
                text={getUsername()}
                onChange={value => changeValue('username', value)}
                onEnterPress={() => generateCognitoChallenge()}
                placeholder="email"
                type="email"
              />
            </div>
          ) : (
            <div className={styles.row}>
              <Input
                text={getToken()}
                onChange={value => changeValue('token', value)}
                onEnterPress={() => generateLoginCredentials()}
                placeholder="token"
                type="number"
              />
            </div>
          )}
          <div className={styles.row}>
            {getStep() === 0 ? (
              <Button
                text="continue"
                onClick={() => generateCognitoChallenge()}
              />
            ) : (
              <Button text="login" onClick={() => generateLoginCredentials()} />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const LoginWithProviders = () => (
  <LoginProviders>
    <Login />
  </LoginProviders>
)

export default LoginWithProviders
